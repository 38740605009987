:root {
  --background-primary: #ffffff;
  --color-primary: black;
  --color-secondary: rgb(55, 55, 55);
  --color-dash: #a2a2a8;
  --background-reverse: #121212;
  --color-reverse: #f5f7fa;
}

.sun {
  --background-primary: #ffffff;
  --color-primary: black;
  --color-secondary: rgb(55, 55, 55);
  --color-dash: #a2a2a8;
  --background-reverse: #121212;
  --color-reverse: #f5f7fa;
}

.moon {
  --background-primary: #121212;
  --color-primary: #f5f7fa;
  --color-secondary: rgb(184, 184, 184);
  --color-dash: #a2a2a8;
  --background-reverse: #a2a2a8;
  --color-reverse: black;
}

html {
  background: var(--background-primary);
  color: var(--color-primary);
}

h1,
h2,
p {
  color: var(--color-primary);
}

textarea{
  width: 300%;
  height: 1.3em;
  color: var(--color-primary);
  background-color:var(--background-primary);
  font-size: 1.25em;
  border-color: var(--color-dash);
  border-width: 0 0 20px;
  resize:both;
  outline: 0;
  overflow: hidden;
}

textarea:focus{
  border-color:var(--color-primary);
}

textarea::placeholder{
  color: var(--color-dash);
}

input[type=submit]{
  background-color: var(--background-reverse);
  color: var(--color-reverse);
  font-size: 1em;
  border-radius: 5px;
}

@media (hover: none) {
  .my-theme_toggle {
    color: var(--color-primary);
  }
}

body {
  background-color: var(--background-primary);
  color: var(--color-primary);
  /* font-family: 'Roboto Mono'; */
  /*importing my custom font
  */
  font-family: 'MyCustomFont';

  font-weight: 400;
  overflow-y: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  width: 0;
  height: 0;
}

a,
a:visited,
a:active,
.theme_toggle,
.theme_toggle:visited,
.theme_toggle:active {
  text-decoration: none;
  /*color: #a2a2a8;*/
  color: var(--color-primary);

}

a:hover,
.theme_toggle:hover {
  color: #a2a2a8;
  cursor: pointer;
}

#outer {
  /* border:1px solid black; */
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  min-height: calc(50vh/3);
  /* flex-basis: calc(100%/3); */
  align-items: center;
}

#inner {
  /* border:1px solid red; */
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-basis: calc(100%/3);
  align-items: flex-start;
  flex-shrink: 10;
}

#blog_header_card {
  /* border:1px solid red; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

#blog_header{
  margin-right: 250px;
  display: flex;
  flex-grow: 9;
}

/* make blog formatting */
#blog{
  /* border: 1px solid blue; */
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-grow: 8;
  max-width: 50%;
  flex-direction: column;
} 


#form {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

#nav_bar {
  /*border: 1px solid #a2a2a8;*/
  text-align: right;
  max-width: max-content;
  margin-right: 5vh;
  flex-grow: 3;
  line-height: 35px;
}

.nav_element {
  color: #a2a2a8 !important;
}

#content {
  flex-grow: 7;
  max-height: max-content;
  /* border: 1px solid black; */
}

.clickable,
.clickable:visited,
.clickable:active {
  cursor: pointer;
  text-decoration: underline;
  /* -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; */
  line-height: normal;
  color: var(--color-primary);
}

.clickable:hover,
.credit:hover {
  color: #a2a2a8;
  /*color: #a2a2a8 !important;*/
}

.credit,
.credit:visited,
.credit:active {
  cursor: pointer;
  text-decoration: underline;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  line-height: normal;
  color: var(--color-primary);

}

.credit_p {
  font-size: small;
  margin-top: -10px;
}

.links {
  letter-spacing: 0.5em;
}

.disclaimer{
  font-size: x-small;
}

.count{
  font-size: x-small;
  text-align: right;
  align-self: flex-start;
  margin-top: -5px;
}